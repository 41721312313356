import React from 'react'

// Context
import { IKContext } from 'imagekitio-react'
import { AuthProvider } from './auth'
import AppContext from './app'
import * as Sentry from '@sentry/react'

// Seed
import { errorDialogOptions } from '../seed'

// utils
import { LocalStorageWorker } from '../utils'

import { BrowserRouter as Router } from 'react-router-dom'
import FallbackPage from '../pages/error/Error'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
})

// required parameter to fetch images from image kit
const urlEndpoint = 'https://ik.imagekit.io/hque'

function AppProviders({ children }) {
  const lsw = new LocalStorageWorker()

  return (
    <Sentry.ErrorBoundary
      showDialog
      dialogOptions={errorDialogOptions}
      fallback={
        <Router>
          <FallbackPage />
        </Router>
      }
    >
      <IKContext urlEndpoint={urlEndpoint}>
        <AuthProvider>
          <AppContext.Provider value={{ lsw }}>{children}</AppContext.Provider>
        </AuthProvider>
      </IKContext>
    </Sentry.ErrorBoundary>
  )
}

export default AppProviders
