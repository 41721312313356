import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Header from '../elements/Header'
import Footer from '../elements/Footer'
import Loading from '../elements/Loading'

import DefaultPage from '../pages/Default'
import PostPage from '../pages/Post'
import ProjectListPage from '../pages/ProjectList'
import ProjectPage from '../pages/Project'
import ProfileListPage from '../pages/ProfileList'
import ProfilePage from '../pages/Profile'
import LoginPage from '../pages/Login'
import RegisterPage from '../pages/Register'
import ConnectRedirectPage from '../pages/LoginRedirect'
import MePage from '../pages/Me'
import FavoritesPage from '../pages/Favorites'
import RequestAccount from '../pages/RequestAccount'
import NewPassword from '../pages/NewPassword'
import PasswordReset from '../pages/PasswordReset'
import BlogListPage from '../pages/BlogList'
import BlogPage from '../pages/Blog'
import NotFound from '../pages/error/Default'
import Chatbox from '../components/chat/inlineWindow'
import PusherHandler from './helpers/PusherHandler'
import EventsPage from '../pages/Events'
import EventReportPage from '../pages/EventReport'

const variant = 'bg-white text-dark'

/**
 * Embed chat code:
 *
 *  <ChatManager anchor="right" className="chat d-flex flex-row" renderChat={({ type, user, onClose }) => (
 *     <Chat onCloseClick={onClose} user={user} type={type}>
 *       {type}: {user.username}
 *     </Chat>
 *  )} />
 *
 * <Route path="/berichten/:channel" component={MessagingPage} />
 * <Route path="/berichten" component={MessagingPage} />
 */

export default () => (
  <div className={`page-wrapper ${variant}`}>
    <PusherHandler />
    <Switch className="stack">
      <Route path="*">
        <Header className={`${variant}`} />
        <Switch className="stack">
          <Route path="/load" component={Loading} />
          <Route path="/login/:oauthError?" component={LoginPage} />
          <Route
            path="/connect/:provider/redirect"
            component={ConnectRedirectPage}
          />
          <Route path="/register/:oauthError?" component={RegisterPage} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/new-password/:code" component={NewPassword} />
          <Route path="/profiel" component={MePage} />
          <Route path="/favorieten" component={FavoritesPage} />

          <Redirect from="/inspiratie/:slug" to="/collections/detail/:slug" />
          <Redirect from="/inspiratie" to="/collections" />

          <Route path="/collections/detail/:slug" component={ProjectPage} />
          <Route
            path="/collections/all/:pageNumber"
            component={ProjectListPage}
          />
          <Route path="/collections/all" component={ProjectListPage} />
          <Route
            path="/collections/:category/:pageNumber"
            component={ProjectListPage}
          />
          <Route path="/collections/:category" component={ProjectListPage} />
          <Route path="/collections" component={ProjectListPage} />

          <Redirect
            from="/members/directory/search_members"
            to="/members/all"
          />
          <Redirect from="/members/directory" to="/members/all" />
          <Route
            sensitive
            path="/members/profile/:slug1*:slug2([A-Z]):slug3*/"
            render={(props) => (
              <Redirect to={`${props.location.pathname.toLowerCase()}`} />
            )}
          />

          <Route path="/members/profile/:slug" component={ProfilePage} />
          <Route path="/members/all/:pageNumber" component={ProfileListPage} />
          <Route path="/members/all/:pageNumber" component={ProfileListPage} />
          <Route path="/members/all" component={ProfileListPage} />
          <Route
            path="/members/:specialisme/:pageNumber"
            component={ProfileListPage}
          />
          <Route path="/members/:specialisme" component={ProfileListPage} />
          <Route path="/members" component={ProfileListPage} />

          <Route path="/lid-worden" component={RequestAccount} />

          <Route path="/stories/:slug" component={PostPage} />
          <Route path="/stories" component={DefaultPage} />

          <Route path="/evenementen/:slug" component={EventReportPage} />
          <Route path="/evenementen" component={EventsPage} />
          <Route path="/blog/all/:pageNumber" component={BlogListPage} />
          <Route path="/blog/all" component={BlogListPage} />
          <Route path="/blog/:slug" component={BlogPage} />
          <Route path="/pagina-niet-gevonden" component={NotFound} />
          <Route path="/:slug" component={DefaultPage} />
          <Route path="/" component={DefaultPage} />
        </Switch>
        {/* // CHAT TODO */}
        {/* <Chatbox/> */}
        <Footer />
      </Route>
    </Switch>
  </div>
)
