import React from 'react'
import useAxios from 'axios-hooks'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Form from '../components/form'
import TagManager from 'react-gtm-module'
import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

export default (props) => {
  const [{ data, loading, error }] = useAxios('testimonials')

  if (loading) {
    return <Loading {...props} />
  }
  if (error || !data.length) {
    return <ErrorPage {...props} />
  }

  const shuffle = (a, limit = 2) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a.slice(0, limit)
  }

  TagManager.dataLayer({
    dataLayer: {
      slug: 'lid-worden',
    },
  })

  return (
    <div className="page register register-professsional-container">
      <Head pageTitle="Lid worden" />
      <ScrollRestoration />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-5 d-flex align-items-center form">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <Form />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-block">
            <div
              className="bg-cover h-100 mr-n3"
              style={{
                backgroundImage:
                  "url('https://s3.eu-west-3.amazonaws.com/enorm.com/Saints_and_Stars_3_252b9d5dab.jpg')",
              }}
            >
              <div className="testimonials p-7">
                {shuffle(data).map((item, key) => (
                  <div
                    key={key}
                    className="testimonial card rounded-lg shadow border-0 my-2"
                  >
                    <div className="testimonial-avatar">
                      <img
                        className="img-fluid"
                        src={item.professional.avatar.url}
                        alt={item.professional.name}
                      />
                    </div>
                    <div className="text">
                      <div className="testimonial-quote">
                        <i className="fas fa-quote-right"></i>
                      </div>
                      <p className="testimonial-text">{item.content}</p>
                      <strong>
                        {item.name} - {item.professional.name}
                      </strong>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
