import { IKImage } from 'imagekitio-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { getImageURL } from '../../core/helpers/Image'
import * as removeMd from 'remove-markdown'
import PlaceholderImage from '../../themes/directory/placeholder.png'

export default ({
  thumbnail,
  date,
  address,
  description,
  event_recap,
  name,
  id,
  ...props
}) => {
  return (
    <div className="card events__body-swiper-card shadow rounded p-1">
      <div className="card-img-top--container p-2">
        {!thumbnail ? (
          <img
            src={PlaceholderImage}
            alt="Placeholder image for an event"
            className="card-img-top--img rounded"
          />
        ) : (
          <IKImage
            className="card-img-top--img rounded"
            path={getImageURL(thumbnail).split('/').pop()}
            srcSet={`
                    https://ik.imagekit.io/hque/tr:w-400,h-300/${getImageURL(
                      thumbnail
                    )
                      .split('/')
                      .pop()} 425w,

                    https://ik.imagekit.io/hque/tr:w-690,h-388/${getImageURL(
                      thumbnail
                    )
                      .split('/')
                      .pop()} 768w,

                    https://ik.imagekit.io/hque/tr:w-750,h-450/${getImageURL(
                      thumbnail
                    )
                      .split('/')
                      .pop()} 1024w,
                    https://ik.imagekit.io/hque/tr:w-750,h-450/${getImageURL(
                      thumbnail
                    )
                      .split('/')
                      .pop()} 1440w,

                  `}
          />
        )}
        {date && (
          <div className="events__body-card-date__secondary card-img-top--date py-1 px-0 text-center rounded">
            <h4 className="m-0">
              {' '}
              <Moment date={date} format="DD" />{' '}
            </h4>
            <p className="m-0">
              {' '}
              <Moment date={date} format="MMM" />{' '}
            </p>
          </div>
        )}
      </div>
      <div className="card-body px-2 py-2 mt-2">
        <h5 className="card-title">{name}</h5>
        <div className="d-flex flex-wrap my-2">
          <small className="events__body-card-tag px-2 py-1 my-lg-0 my-md-0">
            {address
              ? `${address[0]?.street}, ${address[0]?.city}`
              : 'Geen adres'}
          </small>
        </div>
        <div className="d-flex flex-column h-100">
          <div className="event__body-card-text-container">
            {/* <p
              style={{
                visibility:
                  description && description !== '' ? 'visible' : 'hidden',
              }}
              className="card-text text-muted"
            >
              {description && description !== ''
                ? description
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
            </p> */}
          </div>
          <div className="event__body-button-container mt-3">
            <Link
              style={{ visibility: event_recap?.slug ? 'visible' : 'hidden' }}
              className="event__body-button btn btn-light btn-outline-primary m-auto float-right rounded"
              to={`/evenementen/${event_recap?.slug}`}
            >
              Bekijk de recap
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
