// Dependencies
import React from 'react'
import { ErrorMessage, useFormikContext, } from 'formik'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const FileField = ({ input }) => {
  const { errors, touched, setFieldValue } = useFormikContext()

  return (
    <div
      className={`form-group form-file__upload form-select__group ${
        touched[input.name] && errors[input.name] ? 'is-invalid' : ''
      }`}
    >
      <label htmlFor={input.name} className="form-label">
        {input.label}
        {input.required && <span>*</span>}
      </label>
      <FilePond
        onupdatefiles={(fileItems) => {
          // Set currently active file objects to this.state
          setFieldValue(
            input.name,
            fileItems.map((fileItem) => fileItem.file)
          )
        }}
        id={input.name}
        type={input.type}
        allowMultiple={true}
        maxFiles={5}
        name={input.name}
        labelIdle='<span class>Sleep hier uw afbeeldingen in of zoek <span class="filepond--label-action">via uw computer</span></span>'
      />
      <ErrorMessage name={input.name}>
        {(msg) => <div className="invalid-feedback">{msg}</div>}
      </ErrorMessage>
     
    </div>
  )
}

export default FileField
