const columnOne = [
  {
    label: "Foto's van de ruimte",
    name: "fotos",
    type: "file",
    multiple: true,
    required: true,
    validationType: "mixed",
    validation: [
      {
        type: "required",
        params: ["Verplicht"],
      },
    ],
  },
];

const columnTwo = [
  {
    label: "Inspiratie",
    name: "inspiratie",
    type: "file",
    multiple: true
    // required: true,
    // validationType: "file",
    // validation: [
    //   {
    //     type: "required",
    //     params: ["Verplicht"],
    //   }
    // ],
  },
];

const stepThree = {
  fields: { columnOne, columnTwo },
};

export default stepThree;
