import React from 'react'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import LazyLoad from 'react-lazyload'
import Markdown from '../components/markdown'
//import Skeleton from 'react-skeleton-loader'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'

import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Breadcrumb from '../elements/Breadcrumb'

import Directory, { Select, Keyword } from '../components/directory'
import PreviewDirectory from '../components/directory/PreviewDirectory'
import Avatar from '../components/elements/avatar'
import Card from '../components/elements/card'

import ScrollAnimation from 'react-animate-on-scroll'

import { useBreakpoint } from '../core/BreakpointProvider'

const Placeholder = () => (
  <div className="row mb-5" key={`profile-sub-items-placeholder`}>
    {[0, 1, 2].map((i) => (
      <div className="col-md-4 col-12" key={`profile-sub-items-card-${i}`}>
        {/* <Skeleton
          width="100%"
          height="450px"
          widthRandomness={0}
          borderRadius={0}
        /> */}
      </div>
    ))}
  </div>
)

export default (props) => {
  const { specialisme } = useParams()
  const { data, isLoading, isError, error } = useQuery(
    ['specialismes', specialisme],
    () => client(`specialismes?slug=${specialisme}`)
  )

  if (isLoading) {
    return <Loading {...props} />
  }
  if (isError) {
    console.log('ERROR PROFILE LIST', error)
    return <ErrorPage {...props} />
  }

  const page = data[0]

  const breakpoints = useBreakpoint()

  const renderItem = (item, index) => (
    <div className="row w-100 mb-5" key={`profile-item-${item.id}`}>
      <div className="col-md-3 col-12">
        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOnce={true}
          delay={1 * 50}
        >
          <Avatar {...item} />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUpSmall"
          animateOnce={true}
          delay={2 * 50}
        >
          <Truncate lines={5} className="d-block text-muted my-4">
            <Markdown content={item.description} />
          </Truncate>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOnce={true}
          delay={3 * 50}
        >
          <Link
            to={`/members/profile/${item.slug}`}
            className="btn btn-primary text-light"
          >
            Bekijk profiel
          </Link>
        </ScrollAnimation>
      </div>
      <div className="col-md-9 col-12">
        {!breakpoints['xs'] ? renderSubItems(item) : null}
      </div>
    </div>
  )

  const renderSubItems = (item) => (
    <LazyLoad height={450} once offset={100} placeholder={<Placeholder />}>
      <PreviewDirectory
        endpoint="projects"
        _limit={3}
        displayPagination={false}
        query={{
          professionals_in: [item.id],
        }}
        renderWrapper={({ results }) => (
          <div className="row mb-5" key={`profile-sub-items-${item.id}`}>
            {results()}
          </div>
        )}
        renderItem={(item, index) => (
          <div
            className="col-xl-4 col-lg-6 col-md-6 col-12"
            style={{ paddingBottom: 30 }}
            key={`profile-sub-items-card-${item.id}`}
          >
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={(index % 3) * 50}
            >
              <Card {...item} />
            </ScrollAnimation>
          </div>
        )}
      />
    </LazyLoad>
  )

  return (
    <div className={`page members`}>
      <Head
        pageTitle="Professionals"
        seo={[
          {
            meta_description: 'Get inspired',
            meta_title: '',
            __component: 'seo.meta',
          },
          {
            og_image: '',
            og_title: '',
            og_description: 'Get inspired',
            __component: 'seo.open-graph',
          },
        ]}
      />
      <ScrollRestoration />

      <div className="container-fluid pt-4 pb-3 px-lg-5">
        <div className="row">
          <div className="col-xl-8">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={1 * 50}
              offset={-50}
            >
              <Breadcrumb
                pages={[
                  {
                    title: 'Professionals',
                    slug: '/members',
                  },
                ]}
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={2 * 50}
              offset={0}
            >
              <h1>
                {page && data.length === 1 ? page.title : 'Professionals'}
              </h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <Directory
        endpoint="professionals"
        query={{
          specialismes_in: page && specialisme ? [page.id] : undefined,
        }}
        hrefBuilder={(pageIndex, props) => {
          if (specialisme) {
            return `/members/${page.slug}/${pageIndex}`
          } else {
            return `/members/all/${pageIndex}`
          }
        }}
        filters={[
          <Keyword
            name="_q"
            placeholder="Zoek op trefwoord"
            className="mb-4 col-md-3 p-0 pr-md-2"
          />,
          <Select
            isGrouped={true}
            className="mb-4 col-md-3 p-0 pr-md-2"
            endpoint="specialismes?parent_null=1"
            name="specialismes_in"
            placeholder="Specialisme"
            selectedValue={[specialisme]}
            // isDisabled={specialisme ? true : false}
          />,
        ]}
        renderItem={renderItem}
        renderWrapper={({
          results,
          renderLoading,
          renderPagination,
          renderFilters,
        }) => (
          <div className="pt-3 pb-3 mx-3 mx-lg-5">
            <div className="container-fluid">
              <div className="row mb-4">{renderFilters()}</div>
              <div className="row mx-n2">{results()}</div>
              <div className="row">{renderPagination()}</div>
            </div>
          </div>
        )}
      />
    </div>
  )
}
