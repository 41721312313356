import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { queryClientApp } from './config/caching'
import { QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import TagManager from 'react-gtm-module'

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
})

const dehydratedState = window.__REACT_QUERY_STATE__

ReactDOM.hydrate(
  <QueryClientProvider client={queryClientApp}>
    <Hydrate state={dehydratedState}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Hydrate>
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
