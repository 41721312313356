// Dependencies
import {useMutation } from 'react-query'

import { client } from '../../utils'

// Context
import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
// Components
import { FormWizard } from '../../components/form/view'
import ModalWrapper from './ModalWrapper'
// Seed
import { inspirationDataFields } from '../../seed/forms/inspirationDataFields'
import { useAuth } from '../../context/auth'

export default ({ ...props }) => {
  const [modalShow, setModalShow] = useState(false)
  const { me } = useAuth()
  let formData

  //mutation for creating deal
  const {
    mutate: mutateDeal,
    isSuccessDeal,
  } = useMutation((values) =>
    client('deals/inspiration', {
      data: values,
    }),
    {
      onError: (error, variables, context) => {
        console.log("Error", error)
      },
      onSettled: (data, error, variables, context) => {
        //push to thank you page
      },
    }
  )

  //mutation for newsletter
  const { mutate, isLoading, isError, error } = useMutation(
    (values) =>
      client('deals/newsletter', {
        data: values,
      }),{
      onError: (error, variables, context) => {
        console.log("Error", error)
      },
      onSettled: (data, error, variables, context) => {
        mutateDeal(formData)
      },
    }
  )
    
  const handleSubmit = (formik, values) => {
    formData = values
    console.log('Submitting edit customer with:', values)
    if (values.nieuwsbrief !== '') {
      mutate(formData)
    } else {
      mutateDeal(formData)
    }
  }

  // Hide Modal when succesfully updated
  React.useEffect(() => {
    isSuccessDeal && setModalShow(false)
  }, [isSuccessDeal])

  return (
    <>
      <Button
        title="Vraag offertes aan"
        className="btn btn-primary"
        variant="none"
        onClick={() => setModalShow(true)}
      >
        Inspiratiepakket ontvangen
      </Button>

      <ModalWrapper
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        dialogClassName="overflow-hidden"
      >
        <Modal.Header closeButton className="px-5 py-4">
          <h4>Ontvang het inspiratiepakket</h4>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">
          {isError && (
            <div className="alert alert-danger" role="alert">
              {error.message}
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <p>
                Praesent ut posuere risus. Phasellus sit amet rhoncus ipsum.
                Suspendisse mattis porttitor nulla. Vivamus id arcu tempor,
                blandit tellus non, lobortis massa. In hac habitasse platea
                dictumst. Duis malesuada venenatis ante a imperdiet. In eu est
                sapien. Proin accumsan interdum risus quis auctor. Vivamus
                condimentum mauris nibh, eget rutrum arcu gravida vitae. In a
                vestibulum sapien.
              </p>
            </div>
          </div>
          <FormWizard
            apiInitialValues={{
              bedrijfsnaam: me ? me.companyName : '',
              email: me ? me.email : '',
              inspiratie: 'Inspiratiepakket',
              algemene_voorwaarden: '',
              nieuwsbrief: '',
            }}
            isLoading={isLoading}
            submitLabel="Mail mij inspiratie"
            buttonPositionClass="justify-content-center"
            fields={inspirationDataFields}
            onHide={() => setModalShow(false)}
            handleSubmit={handleSubmit}
            {...props}
          />
        </Modal.Body>
      </ModalWrapper>
    </>
  )
}
