// Dependencies
import React from "react";
import { ErrorMessage, useFormikContext } from "formik";
import Select from "react-select";

const SelectField = ({ input }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  const values = [];

  input.select &&
    input.select.values &&
    input.select.values.map((item, key) => {
      values[key] = { value: item, label: item };
      return false;
    });

  return (
    <div
      className={`form-group form-select__group ${
        touched[input.name] && errors[input.name] ? "is-invalid" : ""
      }`}
    >
      <label htmlFor={input.name} className="form-label">
        {input.label}
        {input.required && <span>*</span>}
      </label>
      <Select
        id={input.name}
        name={input.name}
        options={values}
        placeholder="Maak een keuze"
        noOptionsMessage={() => "Geen opties beschikbaar"}
        classNamePrefix="form-select"
        defaultValue={{ label: input.select.values[0], value: input.select.values[0]}}
        onChange={(value) => {
          setFieldValue(input.name, value.value);
        }}
      />
      <ErrorMessage name={input.name}>
        {(msg) => <div className="invalid-feedback">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default SelectField;
