// Dependencies
import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
// Components
import {
  SelectField,
  FileField,
  CheckboxField
} from "./view";

const FormField = ({ input, initialValue = null }) => {
  const { errors, touched } = useFormikContext();

  switch (input.type) {
    case "file":
      return <FileField input={input} />
    case "select":
      return <SelectField input={input} />;
      case "checkbox":
        return <CheckboxField input={input} />;
    default:
      return (
        <div className="form-group">
          <label htmlFor={input.name} className="form-label">
            {input.label}
            {input.required && <span>*</span>}
          </label>
          <Field
            placeholder={input.placeholder ? input.placeholder : ""}
            as={input.type === "textarea" ? "textarea" : "input"}
            id={input.name}
            name={input.name}
            type={input.type}
            required={input.required}
            className={`form-control ${
              touched[input.name] && errors[input.name] ? "is-invalid" : ""
            }`}
          />
          <ErrorMessage name={input.name}>
            {(msg) => <div className="invalid-feedback">{msg}</div>}
          </ErrorMessage>
        </div>
      );
  }
};

export default FormField;
