import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from 'react-query'
// import Head from '../core/Head'
import { client } from '../utils/api-client'
import Breadcrumb from '../elements/Breadcrumb'
import Loading from '../elements/Loading'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import ScrollAnimation from 'react-animate-on-scroll'
import { formatTimeRange } from '../utils/strapi'
import RecapContent from '../blocks/recapContent'
import { useAuth } from '../context/auth'
import ScrollControl from '../elements/ScrollControl'
import EventRegister from '../components/events/EventRegister'

export default (props) => {
  let { slug } = useParams()
  const { me } = useAuth()

  const { data, isLoading } = useQuery(['eventReports', slug], () =>
    client(`event-reports?slug=${slug}`)
  )

  const page = data && data[0]
  const seo = page && page['SEO']
  const event =
    page &&
    (page.type === 'detail' ? page.detail_for_event : page.recap_for_event)

  return isLoading ? (
    <Loading {...props} />
  ) : page ? (
    <div className={`page default navbar-transparant ${page.slug}`}>
      <Head seo={seo} pageTitle={page.title || 'Default'} />
      <ScrollRestoration />
      <div className={`page default navbar-transparant`}>
        <Head seo={seo} pageTitle={page.title || 'Default'} />
        <section className="blog__detail">
          <div className="blog__inner">
            <ScrollRestoration />

            <ScrollAnimation
              animateIn="animate__animated animate__kenBurns"
              animateOnce={true}
              delay={6 * 10}
            >
              <div className="container justify-content-center ">
                <div className="row justify-content-center text-center">
                  <Breadcrumb
                    pages={[
                      {
                        slug: '/evenementen',
                        title: 'Evenementen',
                      },
                      {
                        slug: '/evenementen/' + page.slug,
                        title: page.title,
                      },
                    ]}
                  />
                </div>

                <div className="row justify-content-center my-4">
                  <div className="col-lg-10">
                    <div className="blog__title">
                      <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-7 text-break text-center">
                          <h1>{page.title}</h1>
                        </div>

                        {/* .col */}
                      </div>
                      <div className="row justify-content-center my-2">
                        <small className="events__body-card-tag px-2 py-1">
                          {event &&
                            formatTimeRange(event.start_time, event.end_time)}
                        </small>
                        <small className="events__body-card-tag px-2 py-1 ml-2">
                          {event &&
                            `${event.address[0].street}, ${event.address[0].city}`}
                        </small>
                      </div>
                      {/* .row */}
                    </div>
                    {page.type === 'detail' && (
                      <EventRegister eventId={event.id} />
                    )}
                    <img
                      className="img-fluid w-100 mt-5"
                      src={
                        page?.cover?.url ?? 'https://placeimg.com/1100/575/any'
                      }
                      alt="Cover"
                    />

                    {page.content.map((entry) => {
                      return <RecapContent {...entry} />
                    })}
                    <a href="/evenementen" className="btn btn-link p-0">
                      {' '}
                      <i className="fas fa-chevron-left mr-2"></i>Bekijk de
                      aankomende evenementen
                    </a>
                    <div className="recap__cta rounded my-5">
                      <div className="container">
                        <div className="row text-center align-items-xl-center justify-content-center">
                          <div className="col-md-8">
                            <div className="d-flex flex-column">
                              <h2>Wil jij naar onze events?</h2>

                              <p>
                                Ontmoeten, kennis delen, ideeën opdoen en
                                inspireren. Word lid van hque!
                              </p>
                            </div>
                            <a
                              href={'/lid-worden'}
                              className="btn btn-primary rounded"
                            >
                              Word lid van HQUE
                            </a>
                          </div>
                        </div>{' '}
                      </div>{' '}
                    </div>
                  </div>
                  {/* .col */}
                  <ScrollControl />
                </div>
                {/* .row */}
              </div>
            </ScrollAnimation>
            {/* .container */}
          </div>
        </section>
      </div>
    </div>
  ) : (
    <Redirect to="/pagina-niet-gevonden" />
  )
}
