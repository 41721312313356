import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useAuth } from '../../context/auth'
import ModalWrapper from '../../elements/modals/ModalWrapper'
import attendeefields from '../../seed/forms/event/attendeefields'
import { client } from '../../utils'
import FormWizard from '../form/FormWizard'

const EventRegister = ({ eventId }) => {
  const { me } = useAuth()
  const [modalShow, setModalShow] = React.useState(false)
  const [successModalShow, setSuccessModalShow] = React.useState(false)
  //mutation for updating the event with the new attendant
  const {
    mutateAsync: updateEvent,
    isLoading: isLoadingEvent,
    isError: isEventError,
    error: eventError,
  } = useMutation(
    ({ id, data }) =>
      client(`events/${id}/request`, {
        data,
        method: 'POST',
      }),
    {
      onSuccess: () => {
        setModalShow(false)
        setSuccessModalShow(true)
      },
    }
  )

  const handleSubmit = async (formik, values) => {
    await updateEvent({
      id: eventId,
      data: {
        full_name: values.naam,
        email: values.email,
        company_name: values.bedrijfsnaam,
        telephone_number: values.telefoonNummer,
        remark: values.opmerking,
      },
    })
  }

  return (
    <>
      <div className="w-100 d-flex justify-content-center">
        <Button
          variant="btn btn-light btn-outline-primary m-auto rounded"
          onClick={() => setModalShow(true)}
        >
          Ik wil dit event bezoeken
        </Button>
      </div>

      <ModalWrapper
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        dialogClassName="overflow-hidden"
        className="quotation--modal"
      >
        <Modal.Header closeButton className="px-5 py-4">
          <h4>Ik wil dit evenement bezoeken</h4>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">
          {/* Errors list */}
          {isEventError && (
            <div className="alert alert-danger" role="alert">
              Er is iets misgegaan bij het verwerken van uw verzoek om het
              evenement te bezoeken
              {console.log(eventError.message)}
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <p>
                Om deel te nemen aan dit evenement, moet u eerst het volgende
                formulier invullen. Na het invullen van dit formulier zullen we
                uw gegevens controleren en spoedig contact met u opnemen.
              </p>
            </div>
          </div>
          <FormWizard
            apiInitialValues={{
              naam: me ? me.firstName + ' ' + me.lastName : '',
              telefoonNummer: me ? me.phone : '',
              email: me ? me.email : '',
            }}
            isLoading={isLoadingEvent}
            submitLabel="Verzenden"
            prevBtn={true}
            prevLabel="Annuleren"
            buttonRounded={true}
            buttonPositionClass="justify-content-between"
            fields={attendeefields}
            onHide={() => setModalShow(false)}
            handleSubmit={handleSubmit}
          />
        </Modal.Body>
      </ModalWrapper>

      <ModalWrapper
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
        size="lg"
        dialogClassName="overflow-hidden"
        className="quotation--modal"
      >
        <Modal.Header closeButton className="px-5 py-4">
          <h4>Uw verzoek is verzonden</h4>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">
          <div className="row">
            <div className="col-12">
              <p>
                Wij hebben uw verzoek ontvangen, wij zullen deze in behandeling
                nemen en zo spoedig mogelijk contact met u opnemen.
              </p>
              <Button
                variant="btn btn-light btn-outline-primary m-auto rounded my-2"
                onClick={() => setSuccessModalShow(false)}
              >
                Sluiten
              </Button>
            </div>
          </div>
        </Modal.Body>
      </ModalWrapper>
    </>
  )
}

export default EventRegister
