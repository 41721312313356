import React from 'react'
import { getImageURL } from '../../core/helpers/Image'
import ReactMarkdown from '../../components/react-markdown'
import InspirationModal from '../../elements/modals/Inspiration'
import QuotationsModal from '../../elements/modals/Quotations'

export default ({ image, title, text }) => {
  return (
   image ?  <div className="blog__cta">
      <div className="container">
        <div className="row align-items-xl-center ">
          <div className="col-md-6">
            <img className="img-fluid" src={getImageURL(image)} alt="Image" />
          </div>
          {/* .col */}
          <div className="col-md-6 text-center align-items-xl-center justify-content-center">
            <h2>{title}</h2>
            <ReactMarkdown content={text} />
            <InspirationModal />
            <QuotationsModal />
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .container */}
    </div>
    // {/* .blog__cta */}
 :  <div className="blog__cta">
  <div className="container">
    <div className="row text-center align-items-xl-center justify-content-center">
    <div className="col-md-8">
        <h2>{title}</h2>
        <ReactMarkdown content={text} />
        <InspirationModal />
        <QuotationsModal />
        </div>
      {/* .col */}
    </div>
    {/* .row */}
  </div>
  {/* .container */}
</div>
// {/* .blog__cta */}
  )}
