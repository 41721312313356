import React from 'react'
import { Link } from 'react-router-dom'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import NewPassword from '../elements/auth/NewPassword'

export default (props) => {
  return (
    <div className={`page login`}>
      <Head />
      <ScrollRestoration />

      <div className="container-fluid px-3">
        <div className="row min-vh-100">
          <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <div className="mb-5">
                <img
                  className="img-fluid mb-3"
                  src="/assets/img/logo.svg"
                  alt="HQUE"
                />
                <h2>Nieuw wachtwoord aanmaken</h2>
              </div>

              <NewPassword />

              <Link className="close-absolute mr-md-5 mr-xl-6 pt-5" to="/">
                <i className="fa fa-times w-3rem h-3rem"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
            <div
              className="bg-cover h-100 mr-n3"
              style={{ backgroundImage: "url('/assets/img/bg-slide.jpg')" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
