import React from 'react'
import ReactMarkdown from 'react-markdown'

export default({content}) => (
    <ReactMarkdown 
      source={content}
      renderers={{link: props => <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">{props.children}</a>}}
    />
  )

  