import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import Head from '../core/Head'
import Area from '../core/Area'
import ScrollRestoration from '../core/ScrollRestoration'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'

import Loading from '../elements/Loading'

import TagManager from 'react-gtm-module'

export default (props) => {
  let { slug } = useParams()

  if (slug === undefined || slug === '') {
    slug = 'home'
  }

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        slug: slug,
      },
    })
    if (slug === "home" && typeof window !== "undefined" &&
     localStorage.getItem('__auth_provider_token__') &&
      localStorage.getItem('__auth_provider_token__') !== null &&
      !window.location.hash) {
        window.location = window.location + '#loaded';
        window.location.reload();
    }
  }, [slug])

  const { data, isLoading } = useQuery(['pages', slug],  () =>
      client(`pages?slug=${slug}`)
  )

  const page = data && data[0]
  const seo = page && page['SEO']

  return (
    isLoading ? <Loading {...props} /> :
    page ? <div className={`page default navbar-transparant ${page.slug}`}>
      <Head seo={seo} pageTitle={page.title || 'Default'} />
      <ScrollRestoration />
      <Area blocks={page.content} slug={page.slug} />
    </div> :  <Redirect to='/pagina-niet-gevonden'/>


  )
}
