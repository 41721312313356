// Dependencies
import React from 'react'
// import { Formik, Form } from "formik";
import * as Yup from 'yup'
// Components
import FormField from '../FormField'
import Wizard from './Wizard'
import WizardStep from './WizardStep'
// Utils
import { createYupSchema } from '../../../utils'

const FormWizard = ({
  apiInitialValues,
  className,
  isLoading,
  submitLabel,
  prevBtn,
  prevLabel,
  buttonPositionClass,
  fields,
  onHide,
  handleSubmit,
  buttonRounded,
  ...props
}) => {
  let initialValues = {}
  const fieldSchema = []
  const validationSchema = []

  // Set formSteps with each step
  const formSteps = Object.keys(fields).map((e) => fields[e])

  // Map over each step
  formSteps.map((data, key) => {
    // Get fields of each column
    const fields = Object.keys(data['fields']).map((e) => data['fields'][e])

    // Set key based on the amount of steps
    fieldSchema[key] = {}

    // Map over each field
    fields.map((field, index) => {
      // Set fieldSchema with validation properties per field from each column
      Object.assign(fieldSchema[key], field.reduce(createYupSchema, {}))
      // Map over each input
      field.map((input) => {
        // Set initialValues with each input initial value
        initialValues[input.name] = input.initialValue ? input.initialValue : ''
        return false
      })
      return false
    })
    return false
  })

  // Update initial values of the form when we have api initial values available
  if (apiInitialValues) {
    initialValues = { ...initialValues, ...apiInitialValues }
  }

  // Map over fieldSchema
  fieldSchema.map((schema) => {
    // Set validationSchema with schema's from all fields per step
    validationSchema.push(Yup.object().shape(schema))
    return false
  })

  const renderSteps = () => {
    return validationSchema.map((schema, key) => {
      // Get columns per step
      const columns = formSteps[key]['fields']
      const title = formSteps[key]['title']
      return (
        <WizardStep key={key} validationSchema={schema}>
          {title && (
            <div className="form__column col-12">
              <h5 className="form__step-label">{title}</h5>
            </div>
          )}
          {renderFields(columns)}
        </WizardStep>
      )
    })
  }

  const renderFields = (columns) => {
    let columnsGrid = 6

    if (Object.keys(columns).length === 1) {
      columnsGrid = 12
    }

    return Object.keys(columns).map((field, index) => {
      if (index === 2) {
        columnsGrid = 12
      }

      return (
        <div key={index} className={`form__column col-lg-${columnsGrid}`}>
          {columns[field].map((input, key) => (
            <FormField
              key={key}
              input={input}
              initialValue={initialValues[input.name]}
            />
          ))}
        </div>
      )
    })
  }

  return (
    <Wizard
      initialValues={initialValues}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      className={className}
      buttonRounded={buttonRounded}
      submitLabel={submitLabel}
      prevBtn={prevBtn}
      prevLabel={prevLabel}
      onHide={onHide}
      buttonPositionClass={buttonPositionClass}
    >
      {validationSchema && renderSteps()}
    </Wizard>
  )
}

export default FormWizard
