import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default (props) => {
  const [show, setShow] = React.useState(true)
  const loginRef = React.createRef(); 
  const homeRef = React.createRef(); 

  const handleClose = ()=>{
    setShow(false)
    if (homeRef.current){
        homeRef.current.click(); 
    }
}
  const handleRedirection = ()=>{
      if (loginRef.current){
          loginRef.current.click(); 
      }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered> 
        <Modal.Body closeButton className = "p-3">
          <button
            className="close"
            type="button"
            onClick={handleClose}
            aria-label="Close"
          >
            <a href = "/" ref = {homeRef} />
            <span aria-hidden="true">&times; </span>
          </button>
          <Modal.Title>Je ontvangt nu een linkje in je mail.</Modal.Title>
          <p>We hebben een mail naar {props.email} gestuurd. <br/>
          In deze mail staat een linkje om je wachtwoord te resetten.<br/>
           Klik op deze link en vul een nieuwe wachtwoord in. <br/> 
           Mocht je nog vragen hebben, of lukt het niet om je wachtwoord opnieuw in te stellen? Neem dan <a href = "mailto:hello@hque.space">contact</a> met ons op.</p>
           <div className = "d-flex flex-row justify-content-center w-100 py-2">
            <a href = "/login" ref = {loginRef} />
          <Button variant="primary" onClick={handleRedirection}>
            Inloggen
          </Button>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}
