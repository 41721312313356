import { QueryClient } from "react-query";

// Shared Cache Settings
const sharedQueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60,
      useErrorBoundary: false,
    },
  },
};

// Server Side Cache Settings
const queryClientServer = new QueryClient(sharedQueryClientConfig);

// Client Side Cache Settings
const queryClientApp = new QueryClient({
  defaultOptions: {
    ...sharedQueryClientConfig.defaultOptions,
    refetchOnWindowFocus: false,
    retry(failureCount, error) {
      if (error.status === 404 || error.status === 403 || error.status === 500)
        return false;
      else if (failureCount < 2) return true;
      else return false;
    },
  },
});

export { queryClientServer, queryClientApp };
