import React from "react"
import { client } from '../../utils'
import * as auth from '../../auth-provider'
import {useAuth} from '../auth'

const Context = React.createContext()
Context.displayName = "ChatContext";

function ContextProvider({children}) {
    const [loggedInProfessional, setLoggedInProfessional] = React.useState()
    const [chats, setChats] = React.useState([])
    const [openChats, setOpenChats] = React.useState([])
    const {roles} = useAuth(); 


   const fetchChats =  async (professional)=>{
    clearChats();
       // check if the user is logged in as a professional first
    let url;
    if (professional){
        url =  `chats?professional.id=${professional}`
    }    
    else{
        url = "users/me/chats"; 
    }

    const fetchedChats = await client(url, {
    token: await auth.getToken(),
    }).catch((error) => console.log('ERROR', error))    
        if (fetchedChats){
            setChats(fetchedChats); 
        }
    }

    React.useEffect(()=>{
        if (loggedInProfessional){
            fetchChats(loggedInProfessional);
        }
       else if (roles.length > 0){
            fetchChats();
        }
    // eslint-disable-next-line
    }, [roles, loggedInProfessional])

    React.useEffect(()=>{
        if (openChats){
            if (openChats.length === 4){
                setOpenChats(oc=>oc.slice( 1, oc.length))  
            }  
        }   
    }, [openChats])


    function loginToProfessional(professionalID){
        setLoggedInProfessional(professionalID)
    }

    function logoutFromProfessional(){
        setLoggedInProfessional();
    }
    
    
    function addChat(chat){ 
    if (chat.user){
        let toBeAdded = {...chat}
        if (!getByParticipants(toBeAdded.professional.id, toBeAdded.user.id)){
            if (!toBeAdded.messages){
                toBeAdded.messages = []
            }        
            setChats(prevItems=>[...prevItems, toBeAdded ]); 
        }
        else{
            toBeAdded = getByParticipants(toBeAdded.professional.id, toBeAdded.user.id)
        }
    openChat(toBeAdded); 
    }
    else{
    // Will open with unauthorized view     
        setChats([chat]);
        openChat(chat);
     }
    }   

    function addMessage(chat, message){
        setChats(prevItems => prevItems.map((prevChat)=>{
            if (prevChat.professional.id === chat.professional.id){
                prevChat.messages = [...prevChat.messages, message]; 
            }
            // If it's a new chat 
            if (!prevChat.id || prevChat.id === null){
                prevChat.id = message.chat.id; 
            }
            return prevChat; 
        }))
    // Open the chat 
    openChat(chat); 
    }

    function openChat(chat) {
         setOpenChats(oc=>{
            let chatAlreadyOpen = false; 
            oc.forEach((oldChat)=>{
                if (chat.id === oldChat.id){
                    chatAlreadyOpen = true;
                }
             })
             if (chatAlreadyOpen === false)
             {
                 return [...oc, chat]
             } 
             else{
                 return oc; 
             }
         })

    }
    
    function closeChat(chat) {
        setOpenChats(oc=>oc.filter(c=>c.id !==chat.id))
    }


    function getByParticipants(professionalID, userID){
        let chatAlreadyExists; 
        chats.forEach((chat)=>{
            if (chat?.professional?.id === professionalID && chat?.user?.id === userID ){
                chatAlreadyExists = chat; 
            }
        })
        return chatAlreadyExists; 
    }

    function checIfExistsByID(chatID, professionalID, userID){
    // Check if the chat is already in the context
     let chatAlreadyExists = false; 
     chats.forEach((chat)=>{
       if (chat.id === chatID){
        chatAlreadyExists = true; 
       }
     })
     return chatAlreadyExists; 
    }


    function clearChats(){
        setChats([])
        setOpenChats([])
    }


    
    return (
        <Context.Provider value={{chats, loggedInProfessional , openChats ,addChat, addMessage ,openChat, closeChat, checkIfChatExists: checIfExistsByID, getByParticipants, clearChats, loginToProfessional, logoutFromProfessional, }}>
            {children}
        </Context.Provider>
    )
}

export {ContextProvider, Context as ChatContext}