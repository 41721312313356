import React from 'react'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'

import './App.scss'
import 'moment/locale/nl'
import { setupClient } from './services/Client'
import PusherProvider from './services/Pusher'
import Router from './core/Router'
import { BreakpointProvider, defaultQueries } from './core/BreakpointProvider'
import HttpsRedirect from 'react-https-redirect'
import { Notifications } from 'react-push-notification';
import AppProviders from './context'

setupClient()

export default () => {
  return (
    <HttpsRedirect>
        <AppProviders>
        <PusherProvider>
          <BreakpointProvider queries={defaultQueries}>
          <Notifications position="top-right" suppressHydrationWarning={true} />
            <Router />
          </BreakpointProvider>
          </PusherProvider>
        </AppProviders>
    </HttpsRedirect>
  )
}
