/** @flow */

import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from '../../../elements'

import { IKImage } from 'imagekitio-react'
import { getImageURL } from '../../../../core/helpers/Image'
/* eslint-disable */

const defaultProps = {
  id: '',
  images: [],
  title: '',
  slug: '',
  path: '/collections/detail',
  className: 'card card-poster h-100 gradient-overlay hover-animate',
  minHeight: '240px',
  format: 'medium',
  disabledLink: false,
  selected: false,
}

/**
 * Render a imageTile card element.
 * @constructor
 * @param {object} props                    - The properties object.
 * @param {(array|object)} props.images     - An array of images or a single Image object.
 * @param {(array)} props.professionals     - An array with the professionals for this is project. The first one will be displayed.
 * @param {string} props.title              - Title to display
 * @param {string} props.slug               - URL slug.
 * @param {string} [props.path]             - Optional base path for the url.
 * @param {string} [props.className]      - Optional className.
 * @return {html}                           - The html <div.card /> element.
 */
const ImageTitleCard = (instanceProps) => {
  const props = { ...defaultProps, ...instanceProps }
  let child = React.createRef();
  let parent = React.createRef();

  React.useEffect(()=>{
    resizeFonts(parent.current , child.current)
  }, [child, parent])

  function resizeFonts(parent, child) {
    // Get the computed styles of the child 
    const childStyle = window.getComputedStyle(child, null);

    // Get the font size 
    let childFont = childStyle.getPropertyValue("font-size")

    // If overflowing, reduce font size until it fits 
    while (isOverflowing(child, parent)) {
        child.style.setProperty('font-size', String(getNumberFromPixels(childFont) - 1) + "px")
        childFont = childStyle.getPropertyValue("font-size")
    }
}


function getNumberFromPixels(pixels) {
    let number = pixels.replace('px', "")
    return Number(number)
}


function isOverflowing(child, parent) {
    // Get the padding of the parent 
    const parentStyle = window.getComputedStyle(parent, null);
    let parentPadding = parentStyle.getPropertyValue("padding")

    // Check if the child is overflowing 
    return (parent.offsetHeight - getNumberFromPixels(parentPadding)) < (child.scrollHeight + getNumberFromPixels(parentPadding)) ||
        (parent.offsetWidth - getNumberFromPixels(parentPadding)) < (child.scrollWidth + getNumberFromPixels(parentPadding));
}

  return (
    <div
      className={`card shadow-lg w-100 hover-animate rounded-0 gradient-overlay ${
        props.selected ? 'border-3 border-primary' : 'border-0 '
      }`}
      style={{ minHeight: props.minHeight }}
      key={`elements-card-${props.id}`}
    >
      {!props.disabledLink && (
        <Link to={`${props.path}/${props.slug}`} className="tile-link"></Link>
      )}

      <IKImage
        className="bg-image"
        path={getImageURL(props.images, props.index, props.format)
          .split('/')
          .pop()}
        srcSet={`
          https://ik.imagekit.io/hque/tr:w-395,h-240/${getImageURL(
            props.images,
            props.index,
            props.format
          )
            .split('/')
            .pop()} 425w,

          https://ik.imagekit.io/hque/tr:w-690,h-240/${getImageURL(
            props.images,
            props.index,
            props.format
          )
            .split('/')
            .pop()} 768w,

          https://ik.imagekit.io/hque/tr:w-690,h-240/${getImageURL(
            props.images,
            props.index,
            props.format
          )
            .split('/')
            .pop()} 1024w,
          https://ik.imagekit.io/hque/tr:w-690,h-240/${getImageURL(
            props.images,
            props.index,
            props.format
          )
            .split('/')
            .pop()} 1440w,
          https://ik.imagekit.io/hque/tr:w-690,h-240/${getImageURL(
            props.images,
            props.index,
            props.format
          )
            .split('/')
            .pop()} 2560w
        `}
      />



      <div className="card-body overlay-content text-truncate text-white text-center align-middle d-table h-100" ref = {parent}> 
       <h2 className="text-shadow align-middle d-table-cell" ref={child}>{props.title} </h2>
      </div>
    </div>
  )
}

export { Card, ImageTitleCard }
