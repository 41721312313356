import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from 'react-query'
// import Head from '../core/Head'
import { client } from '../utils/api-client'
import Breadcrumb from '../elements/Breadcrumb'
import Loading from '../elements/Loading'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Card from '../components/elements/card'
import BlogCta from '../blocks/blogCta/View'
import Hero from '../blocks/hero/View'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import ReactMarkdown from 'react-markdown'
import ScrollAnimation from 'react-animate-on-scroll'


export default (props) => {
  let swiper
  useEffect(() => {
    new Swiper(swiper, {
      slidesPerView: 'auto',
      speed: 1500,
      loop: true,
      effect: 'swipe',
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
      breakpoints: {
        576: {
          slidesPerView: 'auto',
          centeredSlides: false,
        },
        768: {
          slidesPerView: 'auto',
          centeredSlides: false,
          loop: false,
        },
        1430: {
          slidesPerView: 'auto',
          centeredSlides: false,
          loop: false,
        },
      },
    })
  })
  let { slug } = useParams()
  Swiper.use([Navigation, Pagination, Autoplay])

  const { data, isLoading } = useQuery(['blog', slug], () =>
    client(`blogs?slug=${slug}`)
  )

  const page = data && data[0]
  const seo = page && page['SEO']

  const renderRelatedItems = (projects) =>
    projects.map((project, index) => {
      return (
        <div
          className="col-md-6 col-lg-4 col-10 mb-5 swiper-slide"
          key={`project-related-items-card-${project._id || project.id}`}
        >
          <Card {...project} />
        </div>
      )
    })

  return (
    isLoading ?   <Loading {...props} /> : page ? 
    <div className={`page default navbar-transparant ${page.slug}`}>
      <Head seo={seo} pageTitle={page.Title || 'Default'} />
      <ScrollRestoration />

      <div className={`page default navbar-transparant`}>
        <Head seo={seo} pageTitle={page.Title || 'Default'} />
        {page.hero && <Hero {...page.hero} />}
        <section className="blog__detail">
          <div className="blog__inner">
          <ScrollRestoration />

          <ScrollAnimation
        animateIn="animate__animated animate__kenBurns"
        animateOnce={true}
            delay={6 * 10}
          >
            <div className="container">
            <Breadcrumb
              pages={[
                {
                  slug: '/blog/all',
                  title: 'Blogs',
                },
                {
                  slug: '/blog/' + page.slug,
                  title: page.Title,
                },
              ]}
            />

              <div className="row justify-content-center my-4">
                <div className="col-lg-10">
                  <div className="blog__title mb-0">
                    <div className="row">
                      <div className="col-lg-10 col-xl-7 text-break">
                        <h1>{page.Title}</h1>
                      </div>
                      {/* .col */}
                    </div>
                    {/* .row */}
                  </div>
                  {/* .blog__title */}

                  <div className="col-lg-10">
                  <div className="blog__date">
                    <div className="row">
                        <p>{page.Date}</p>
                      {/* .col */}
                    </div>
                    {/* .row */}
                  </div>
                  </div>
                  {/* .blog__title */}
                  <div className="row justify-content-center">
                  <div className="blog__image my-0">
                    <img
                      className="img-fluid"
                      src={page?.cover?.url ?? "https://placeimg.com/1100/575/any"}
                      alt="Image"
                    />
                  </div>
                  </div>
                  <div className="blog__tags">
                    <div className="row">
                      <div className="col">
                        <div className="tags">
                          {/* {page.blog.map((blog) => {
                            return <div className="tag">{blog.title}</div>
                          })} */}
                        </div>
                        {/* .tags */}
                      </div>
                      {/* .col */}
                    </div>
                    {/* .row */}
                  </div>
                  {/* .blog__tags */}
                  <div className="blog__text">
            <ReactMarkdown>
              {page.Text}
              </ReactMarkdown>
                </div>             
         
                </div>
                {/* .col */}
              </div>
              {/* .row */}
            </div>
            </ScrollAnimation>
            {/* .container */}
          </div>
          {/* .blog__inner */}


          {page.blogCta && <BlogCta {...page.blogCta} />}

          {page.related_projects.length > 0 ? (
            <div className="blog__related pt-6">
              <div className="container">
                <div className="row mx-1 mb-4">
                  <h2 className="h3">Gerelateerde projecten</h2>
                </div>
                <div className="row justify-content-center">
                  <div
                    className="swiper-container h-100 blog__related-swiper"
                    ref={(c) => (swiper = c)}
                  >
                    <div className="swiper-wrapper">
                      {renderRelatedItems(page.related_projects)}
                    </div>
                    <div className="swiper-pagination blog__related-swiper-pagination"></div>
                  </div>
                </div>
              </div>

            </div>

          ) :  null}
        </section>
        {/* .blog__detail */}
        
      </div>
   
    </div> : <Redirect to = "/pagina-niet-gevonden"/>
  )
}
