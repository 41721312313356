import LocalStorageWorker from './LocalStorage'
import { generateErrorMessageArray, getImageURL } from './strapi'
import difference from './Helpers'
import { client } from './api-client'
import { createYupSchema } from "./yup";

let canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export {
  LocalStorageWorker,
  generateErrorMessageArray,
  getImageURL,
  difference,
  client,
  canUseDOM,
  createYupSchema,
}
