// Dependencies
import React, { useState } from 'react'
import { Formik, Form } from 'formik'

const Wizard = ({
  children,
  initialValues,
  isLoading,
  onSubmit,
  className,
  submitLabel,
  buttonPositionClass,
  prevBtn,
  prevLabel,
  onHide,
  buttonRounded,
}) => {
  const [stepNumber, setStepNumber] = useState(0)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = (values) => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = (values) => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }
    if (isLastStep) {
      return onSubmit(bag, values)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  const renderDots = () => {
    return steps.map((step, key) => {
      return (
        <li
          key={key}
          className={`form__step-dots-item ${
            key === stepNumber ? 'active' : ''
          }`}
        ></li>
      )
    })
  }

  return (
    <div className="formidable">
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => (
          <Form className={`${className ? className : ''}`} noValidate>
            <div className="row">{step}</div>

            <div className={`row ${buttonPositionClass} align-items-baseline`}>
              {prevBtn && (
                <button
                  className="btn"
                  type="button"
                  onClick={() =>
                    stepNumber > 0 ? previous(formik.values) : onHide()
                  }
                >
                  {prevLabel ? prevLabel : 'Ga terug'}
                </button>
              )}
              {steps.length > 1 && (
                <div className="form__step-dots row">
                  <ul className="form__step-dots-list">{renderDots()}</ul>
                </div>
              )}
              <button
                className={`btn btn-primary text-light ${
                  buttonRounded ? 'rounded' : ''
                }`}
                type="submit"
                disabled={formik.isSubmitting || isLoading || !formik.isValid}
              >
                {isLastStep
                  ? submitLabel
                    ? submitLabel
                    : 'Verzenden'
                  : 'Volgende'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Wizard
