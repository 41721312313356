// Dependencies
import React, {useState} from "react";
import { ErrorMessage, useFormikContext } from "formik";

const CheckboxField = ({ input, onChange }) => {
  const { errors, touched, setFieldValue } = useFormikContext();
  const [state, setState] = useState(false)

  const renderCheckboxes = () => {
    const values = input.checkbox && input.checkbox && input.checkbox.values;
    return values.map((value, key) => (
      <div key={key} className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={`${input.name}[${key}]`}
          name={`${input.name}[${key}]`}
          required={input.required}
          value={value}
          checked={state}
          className={`custom-control-input ${
            touched[input.name] && errors[input.name] ? "is-invalid" : ""
          }`}
          onChange={(e) => {
            setState(e.target.checked)
            if(state === false) {
              value = e.target.value
            }else{
              value = ""
            }
            setFieldValue(input.name, value);
          }}
        />
        <label
          className="custom-control-label"
          htmlFor={`${input.name}[${key}]`}
        >
          {value}
          {input.required && <span>*</span>}
        </label>
      </div>
    ));
  };

  return (
    <div className="form-group">
      {renderCheckboxes()}
      <ErrorMessage name={input.name}>
        {(msg) => <div className="invalid-feedback">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default CheckboxField;
