import React from 'react'
import Markdown from 'markdown-to-jsx'

export default({content, className}) => {
  const ExternalLink = ( {children, ...props}) => (
    <a href={props.href} title={props.title} rel="nofollow noreferrer noopener" target="_blank">{children}</a>
  )

  return (
    <Markdown
    className={className}
    options={{
      overrides: {
        a: {
          component: ExternalLink
        }
      }
    }}>
      {content}
    </Markdown>
  )
}
