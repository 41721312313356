import { IKImage } from 'imagekitio-react'
import React from 'react'
import ReactMarkdown from '../../components/react-markdown'
import { getImageURL } from '../../core/helpers/Image'
import Gallery from '../../elements/Gallery'
const RecapContent = ({ header, body, images, id }) => {
  return (
    <div className="blog__content my-5" key={id}>
      <div className="row">
        <div className="col">
          <h2>{header}</h2>
          <div className="text-break">
            <ReactMarkdown content={body} />
          </div>
          {images.length > 0 &&
            (images.length === 1 ? (
              <img
                className="img-fluid w-100 mt-5"
                src={images[0].url ?? 'https://placeimg.com/1100/575/any'}
                alt="event recap content"
              />
            ) : (
              <Gallery
                title={header}
                images={images}
                numberOfThumbs={images.length}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
export default RecapContent
