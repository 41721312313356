import moment from 'moment'; 
import Moment from 'react-moment'; 
import React from 'react'; 

const formatTimeRange = (start_time, end_time)=>{
    const dateObj = new Date();
    const dateStr = dateObj.toISOString().split('T').shift();
     const formattedDate = {
     start: moment(dateStr + ' ' + start_time).toDate(), 
     end:moment(dateStr + ' ' + end_time).toDate()
    };
    return <> <Moment  date= {formattedDate.start} format="HH:mm" />- <Moment  date= {formattedDate.end} format="HH:mm" /> </>
}

export default formatTimeRange; 
