class LocalStorageWorker {
  localStorageSupported

  constructor() {
    this.localStorageSupported =
      typeof window !== 'undefined' &&
      typeof window['localStorage'] != 'undefined' &&
      window['localStorage'] != null
  }

  // add value to storage
  add(key, item) {
    this.localStorageSupported &&
      localStorage.setItem(key, JSON.stringify(item))
  }

  update(key, updates) {
    let existing = this.get(key) || null
    let newData

    if (existing) {
      newData = { ...existing, ...updates }
    } else {
      newData = updates
    }

    this.localStorageSupported && this.add(key, newData)
  }

  // get all values from storage (all items)
  getAllItems() {
    let list = []

    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)

      if (key != null) {
        let value = localStorage.getItem(key)

        list.push({
          key: key,
          value: value,
        })

        return list
      }
    }

    return list
  }

  // get only all values from localStorage
  getAllValues() {
    let list = []

    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)

      if (key != null) {
        let value = localStorage.getItem(key)
        list.push(value)
        return list
      }
    }

    return list
  }

  // get one item by key from storage
  get(key) {
    if (this.localStorageSupported) {
      let item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } else {
      return null
    }
  }

  // remove value from storage
  remove(key) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key)
    }
  }

  // clear storage (remove all items from it)
  clear() {
    if (this.localStorageSupported) {
      localStorage.clear()
    }
  }
}

export default LocalStorageWorker
