import React from 'react'
import Head from '../../core/Head'
import ScrollRestoration from '../../core/ScrollRestoration'
import BlockHero from '../../blocks/hero/View'

export default (props) => {
  return (
    <div className={`page`}>
      <Head
        pageTitle="500 - Error"
        seo={[
          { meta_description: '', meta_title: '', __component: 'seo.meta' },
          {
            og_image: '',
            og_title: '',
            og_description: '',
            __component: 'seo.open-graph',
          },
        ]}
        {...props}
      />
      <ScrollRestoration />
      <BlockHero
        error = {true}
        title="500"
        content={<span>Ooow, voor deze ruimte missen we nog wat inspiratie… <br/> Zorg dat jouw werkruimte niet zo saai en leeg blijft!</span>}
        image={{
          url: 'NotFound_XLArJmlHQ.png',
        }}
        key="error-hero-404"
        slug="404"
        link={{
          url: '/collections',
          title: 'Get inspired!',
          class: 'btn btn-light text-gold '
        }}
      />
      <section className="">
        <div className="container py-7">
          <div className="row text-center">
            <div className="col-12">
              <a href="/" className="btn btn-primary btn-large">
                Terug naar home
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
