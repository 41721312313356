import Moment from 'react-moment'
import { Button, Modal } from 'react-bootstrap'
import React from 'react'
import { formatTimeRange } from '../../utils/strapi'
import { useMutation } from 'react-query'
import { client } from '../../utils'
import attendeefields from '../../seed/forms/event/attendeefields'
import { useAuth } from '../../context/auth'
import FormWizard from '../../components/form/FormWizard'
import ModalWrapper from '../../elements/modals/ModalWrapper'
import { Link } from 'react-router-dom'

export default ({
  id,
  name,
  date,
  start_time,
  end_time,
  maximum_visitors,
  event_detail,
  address,
  attendants, // The approved attendants only
}) => {
  return (
    <>
      <div
        key={id}
        className="card col-12 container shadow mb-3 bg-white rounded"
      >
        <div className="events__body-card row px-1 py-3 d-flex justify-content-between pr-0">
          <div className="col-12 col-lg-9 col-md-8 container">
            <div className="row">
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-2">
                <div className="events__body-card-date  py-1 px-0 text-center rounded">
                  <p className="m-0 events__body-card-date__day">
                    {' '}
                    <Moment date={date} format="DD" />{' '}
                  </p>
                  <p className="m-0 events__body-card-date__month">
                    {' '}
                    <Moment date={date} format="MMM" />{' '}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column col-xl-11 col-lg-10 col-md-10  col-sm-10 col-10 pl-sm-0  pl-xl-3 pl-lg-0 pl-md-0 pl-3 justify-content-between">
                <p className="events__body-card-title">{name}</p>
                <div className="events__body-tags-container d-flex flex-wrap">
                  <small className="events__body-card-tag px-2 mr-2 py-1 my-lg-0 my-md-0 my-2">
                    {formatTimeRange(start_time, end_time)}
                  </small>
                  {address[0]?.street && address[0]?.city && (
                    <small className="events__body-card-tag px-2 py-1 mr-2 my-lg-0 my-md-0 my-2">{`${address[0]?.street}, ${address[0]?.city}`}</small>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 col-md-4 d-flex mt-4 mt-md-0 mt-lg-0">
            <Link
              className={`btn btn-light btn-outline-primary m-auto rounded ${
                !event_detail ? 'disabled' : ''
              }`}
              to={
                event_detail
                  ? `/evenementen/${event_detail?.slug}`
                  : '/evenementen'
              }
              disabled={!event_detail}
            >
              {!event_detail
                ? 'Binnenkort meer over dit evenement'
                : 'Meer over dit evenement'}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
