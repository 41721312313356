import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'
import { Button, Navbar } from 'react-bootstrap'

import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Breadcrumb from '../elements/Breadcrumb'

import Directory, { Card, Select, Keyword } from '../components/directory'
import ScrollAnimation from 'react-animate-on-scroll'
import qs from 'qs'

export default (props) => {
  const { category } = useParams()

  const { data, isLoading, isError, error } = useQuery(
    ['projectCategories', category],
    () => client('categories?' + qs.stringify({ slug: category }))
  )

  if (isLoading) {
    return <Loading {...props} />
  }
  if (isError && isError !== null) {
    console.log('ERROR', error)
    return <ErrorPage {...props} />
  }

  const page = data[0]

  return (
    <div className={`page projects`}>
      <Head
        pageTitle="Inspiratie"
        seo={[
          {
            meta_description: 'Get Inspired',
            meta_title: 'Inspiratie',
            __component: 'seo.meta',
          },
          {
            og_image: '',
            og_title: 'Inspiratie',
            og_description: 'Get inspired',
            __component: 'seo.open-graph',
          },
        ]}
      />
      <ScrollRestoration />

      <div className="container-fluid pt-4 pb-3 px-lg-5">
        <div className="row">
          <div className="col-xl-8">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={1 * 50}
              offset={-50}
            >
              <Breadcrumb
                pages={[
                  {
                    title: 'Inspiratie',
                    slug: '/collections',
                  },
                ]}
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={2 * 50}
            >
              <h1>{page && data.length === 1 ? page.title : 'Inspiratie'}</h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <Directory
        endpoint="projects"
        displayPagination={true}
        query={{
          categories_in: page && category ? [page.id] : undefined,
        }}
        hrefBuilder={(pageIndex, props) => {
          if (category) {
            return `/collections/${page.slug}/${pageIndex}`
          } else {
            return `/collections/all/${pageIndex}`
          }
        }}
        filters={[
          <Keyword name="_q" placeholder="Zoek op trefwoord" className="col" />,
          <Select
            className="col"
            endpoint="categories?parent_null=1"
            name="categories_in"
            placeholder="Category"
            isGrouped={true}
            selectedValue={[category]}
          />,
          <Select
            className="col"
            endpoint="styles"
            name="styles_in"
            placeholder="Style"
          />,
        ]}
        renderWrapper={({
          results,
          renderLoading,
          renderPagination,
          renderFilters,
        }) => (
          <div className="pt-3 pb-3 mx-3 mx-lg-5">
            <div className="container-fluid">
              <div className="row mb-4" style={{ margin: '0 -30px' }}>
                <Navbar collapseOnSelect expand="lg" className="w-100 px-lg-0">
                  <Navbar.Toggle
                    aria-controls="responsive-filters"
                    className="border-0 m-0 p-0 w-100 mb-3"
                  >
                    <Button
                      variant="outline-primary"
                      block
                      className="btn-wide d-block"
                    >
                      Filteren
                    </Button>
                  </Navbar.Toggle>

                  <Navbar.Collapse
                    id="responsive-filters"
                    className="collapse navbar-collapse"
                  >
                    {renderFilters()}
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <div className="row my-2" style={{ margin: '0 -30px' }}>
                {renderLoading()}
                {results()}
              </div>
              <div className="row" style={{ margin: '0 -30px' }}>
                {renderPagination()}
              </div>
            </div>
          </div>
        )}
        renderItem={(item, index) => (
          <div
            className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 mt-1"
            key={`${index}-${item.id}`}
          >
            <ScrollAnimation
              animateIn="animate__animated animate__fadeIn"
              offset={0}
              animateOnce={true}
              delay={(index % 4) * 50}
            >
              <Card {...item} />
            </ScrollAnimation>
          </div>
        )}
      />
    </div>
  )
}
