import React from 'react'
import { useParams } from 'react-router-dom'
import Head from '../core/Head'
import Area from '../core/Area'
import ScrollRestoration from '../core/ScrollRestoration'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'

import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

export default (props) => {
  let { slug } = useParams()

  if (slug === undefined || slug === '') {
    slug = 'home'
  }

  const { data, isLoading, isError, error } = useQuery(['stories', slug], () =>
    client(`post?slug=${slug}`)
  )

  if (isLoading) {
    return <Loading {...props} />
  }
  if (isError || !data.length || !slug) {
    console.log('STORIES ERROR', error)
    return <ErrorPage {...props} />
  }

  const page = data[0]
  const seo = page['SEO']

  return (
    <div className={`page navbar-transparant ${page.slug}`}>
      <Head pageTitle={page.title} seo={seo} />
      <ScrollRestoration />
      <Area blocks={page.content} slug={page.slug} />
    </div>
  )
}
