import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import useAxios from 'axios-hooks'
import * as auth from '../auth-provider'
import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

export default (props) => {
  const [message] = useState(<Loading />)
  const location = useLocation()
  const params = useParams()

  const [{ data, loading, error }] = useAxios(
    `/auth/${params.provider}/callback${location.search}`
  )

  if (loading) {
    return <Loading {...props} />
  }
  if (error) {
    // redirect if malformed oauth token or user already exists
    if (error.response.data && error.response.data.message) {
      return window.location.replace(
        `${process.env.REACT_APP_URL}/login?error=${error.response.data.message.error.message}`
      )
    }

    return <ErrorPage {...props} />
  }

  if (data) {
    auth.handleUserResponse({ jwt: data.jwt })
    return window.location.replace(`${process.env.REACT_APP_URL}`)
  }

  return (
    <div className={`page`}>
      <Head />
      <ScrollRestoration />

      <div className="container-fluid px-3">
        <div className="row min-vh-100">
          <div className="col-12 d-flex align-items-center">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <div className="mb-5">
                <img
                  className="img-fluid mb-3"
                  src="/assets/img/logo.svg"
                  alt="HQUE"
                />
                <h2>Inloggen</h2>
              </div>

              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
