import React from 'react'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Register from '../elements/auth/Register'

export default (props) => {
  return (
    <div className={`page login`}>
      <Head />
      <ScrollRestoration />

      <div className="container-fluid px-3">
        <div className="row min-vh-100">
          <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <h1 className="mt-5">Registreren</h1>

              <Register />
            </div>
          </div>
          <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
            <div
              className="bg-cover h-100 mr-n3"
              style={{ backgroundImage: "url('/assets/img/bg-slide.jpg')" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
