const columnOne = [
  {
    label: 'Voornaam - Achternaam',
    name: 'naam',
    type: 'text',
    required: true,
    validationType: 'string',
    validation: [
      {
        type: 'required',
        params: ['Verplicht'],
      },
    ],
  },
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    required: true,
    validationType: 'string',
    validation: [
      {
        type: 'required',
        params: ['Verplicht'],
      },
      {
        type: 'email',
        params: ['Ongeldig email adres'],
      },
    ],
  },

  {
    label: 'Opmerking',
    name: 'opmerking',
    type: 'textarea',
    validationType: 'string',
  },
]

const columnTwo = [
  {
    label: 'Bedrijfsnaam',
    name: 'bedrijfsnaam',
    type: 'text',
    required: true,
    validationType: 'string',
    validation: [
      {
        type: 'required',
        params: ['Verplicht'],
      },
    ],
  },

  {
    label: 'Telefoonnummer:',
    name: 'telefoonNummer',
    type: 'tel',
  },
]

const attendeefields = {
  stepOne: {
    fields: { columnOne, columnTwo },
  },
}

export default attendeefields
