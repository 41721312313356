
import addNotification from 'react-push-notification';

  const handleClick = ((e, redirectUrl, native, history) => {
    e.preventDefault()

    if (redirectUrl){
    if(native) {
        window.focus()
        window.open(process.env.REACT_APP_URL+ redirectUrl, "_self")
    }else {
        history.push(redirectUrl)
    }
  }
  })

  //function that sends notification
  export default function notify(message, native,redirectUrl ,title, icon, history){
    let options; 

    if (native) {
      options = {
        title,
        message,
        duration: 5000, 
        native,
        icon,
        onClick: (e) => handleClick(e, redirectUrl, native, history)
      }
    }
    else {
      options = {
        title,
        message, 
        duration: 5000, 
        native,
        onClick: (e) => handleClick(e, redirectUrl, native, history),
        backgroundTop: "#c08d2d", 
        backgroundBottom: "white",
        colorTop: 'white', 
        colorBottom: 'black'
      };
    }
  
    addNotification(options);
  } 



