import React from 'react'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import notify from '../../components/push-notifications'
import { client } from '../../utils'
import * as auth from '../../auth-provider'
import { useHistory } from 'react-router-dom'
import { ChatContext } from '../../context/chat'
import Placeholder from '../../seed/icons/placeholder-icon.svg'
import { useAuth } from '../../context/auth'

export default () => {
  const history = useHistory()
  // const {addChat, addMessage, getByParticipants, loggedInProfessional} = React.useContext(ChatContext);
  //get logged in user
  const { me: user } = useAuth()

  const NewMessagesHandler = async (notifiedData, history) => {
    // Fetch the message object
    const message = await client(`messages/${notifiedData.message}`, {
      token: await auth.getToken(),
    }).catch((error) => console.log('ERROR', error))

    // If the chat already exists, add the message to it.
    //  let existingChat = getByParticipants(message.professional.id, message.user.id)
    //  if (existingChat){
    // addMessage(existingChat ,message)
    //  }

    // Otherwise, create a new chat object.
    //  else{
    //   const chat = await client(`chats/${notifiedData.chat}`, {
    //     token: await auth.getToken(),
    //   }).catch((error) => console.log('ERROR', error))
    //   //  addChat(chat);
    //  }
    //  if ((loggedInProfessional && message.from_user === true) || (!loggedInProfessional && message.from_user === false)){
    //   notify(message.body, !document.hasFocus(), !loggedInProfessional && `/members/profile/${message?.professional?.slug}` , loggedInProfessional ? message?.user.firstName + " " + message.user.lastName : message.professional.name, (!loggedInProfessional ?  message.professional.avatar?.url : message.user.avatar?.url) ?? Placeholder, history)
    // }
  }

  //subscribe to private channel
  const channel = useChannel(`private-notifications-${user && user.id}`)

  //bind notification event to channel
  useEvent(channel, 'new-project', (data) =>
    notify(
      data.message,
      !document.hasFocus(),
      `/collections/detail/${data.slug}`,
      'Nieuw Project',
      history
    )
  )

  useEvent(channel, 'new-message', async (data) => {
    await NewMessagesHandler(data, history)
  })
  return null
}
