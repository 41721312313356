import React from 'react'
import { useQuery } from 'react-query'
import Head from '../core/Head'
import { client } from '../utils/api-client'
import {Redirect} from 'react-router-dom'
import Loading from '../elements/Loading'

import BlockHero from '../blocks/hero/View'
// import ScrollAnimation from 'react-animate-on-scroll'
import Directory from '../components/directory'
import { getImageURL } from '../core/helpers/Image'
import { Link } from 'react-router-dom'
import Card from '../components/elements/card-blog'
import { IKImage } from 'imagekitio-react'

export default (props) => {

  const { data, isLoading } = useQuery(['blog'], () =>
    client(`pages?slug=blog`)
  )

  const page = data && data[0]
  const seo = page && page['SEO']

  const renderRow = (classname, { Title, Date, Text, cover, slug, id }) => (
    <div className={`blog__row ${classname}`} key={id}>
      <div className="container">
        <div className="row align-items-xl-center">
          <div className="col-md-6 col-xl-7">
            <Link to={`/blog/${slug}`} title="Lees dit artikel">
              <IKImage
                className="img-fluid card-img-top"
                path={getImageURL(cover).split('/').pop()}
                srcSet={`
                    https://ik.imagekit.io/hque/tr:w-400,h-300/${getImageURL(
                      cover
                    )
                      .split('/')
                      .pop()} 425w,

                    https://ik.imagekit.io/hque/tr:w-690,h-388/${getImageURL(
                      cover,
                    )
                      .split('/')
                      .pop()} 768w,

                    https://ik.imagekit.io/hque/tr:w-750,h-450/${getImageURL(
                      cover,
                    )
                      .split('/')
                      .pop()} 1024w,
                    https://ik.imagekit.io/hque/tr:w-750,h-450/${getImageURL(
                      cover,
                    )
                      .split('/')
                      .pop()} 1440w,

                  `}
              />
            </Link>
          </div>
          {/* .col */}
          <div className="col-md-6 col-xl-5">
            <div className="card">
              <div className="card-body">
                <p className="card-date">{Date}</p>
                <p className="card-title">{Title}</p>
                {/* .card-date */}
                <p className="card-text text-truncate">{Text}</p>
                {/* .card-title */}
                <Link
                  className="btn btn-link stretched-link"
                  to={`/blog/${slug}`}
                >
                  Lees dit artikel
                </Link>
                {/* .btn */}
              </div>
              {/* .card-body */}
            </div>
            {/* .card */}
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .container */}
    </div>
  )

  return (
  isLoading ? <Loading {...props} /> : page ?   <div className={`page default navbar-transparant `}>
      <Head seo={seo} pageTitle={page.title || 'Default'} />

      {/* Hero */}
      <BlockHero
        title="Inspiratie voor de perfecte werkplek"
        image={{
          url: getImageURL(page.image),
        }}
        key="blog-hero"
        slug="blog"
      />

      {/* DIRECTORY */}
      <Directory
        _limit={14}
        endpoint="blogs"
        displayPagination={true}
        _sort="createdAt:DESC"
        hrefBuilder={(pageIndex, props) => {
          return `/blog/all/${pageIndex}`
        }}
        renderWrapper={({ results, renderLoading, renderPagination }) => {
          return (
            <section className="blog__overview">
              <div className="blog__row">
                <div className="container">
                  <div className="row">
                    {renderLoading()}
                    {results()}
                  </div>
                </div>
              </div>
              <div className="blog__pagination">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      {renderPagination()}
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .container */}
              </div>
              {/* .blog__pagination */}
            </section>
          )
        }}
        renderItem={(item, index) => {
          if (index === 0) {
            return renderRow('blog__row-top', item)
          }

          if (index === 4) {
            return renderRow('blog__row-bottom', item)
          }

          return (
            <div className="col-md-6 col-lg-4 hover-animate" key={item.id}>
              <Card {...item} />
            </div>
          )
        }}
      />
    </div> :   <Redirect to = "/pagina-niet-gevonden"/>

  )
}
