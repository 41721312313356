import React from 'react'

import { Link } from 'react-router-dom'
import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'

import Breadcrumb from '../elements/Breadcrumb'

import ProfileForm from '../elements/auth/Profile'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'
import * as auth from '../auth-provider'
import { useProfileCompletion } from '../utils/hooks'

export default (props) => {
  const { data: me } = useQuery('me', async () =>
    client('users/me', { token: await auth.getToken() })
  )

  const { progress } = useProfileCompletion(me)

  // currently checks if one of the subscribed topics is empty
  function renderTopicStep(array, item, key) {
    if (!array || Object.keys(array).some((key) => array[key].length === 0)) {
      return (
        <li className="my-4" key={key}>
          <span className="text-link">{item.title}</span>
          <hr className="mt-4" />
        </li>
      )
    } else {
      return (
        <li className="my-4" key={key}>
          <Link to={item.handler.url} key={key}>
            <span className="text-muted">{item.title}</span>
            <i className="fal fa-check-circle text-success ml-2"></i>
          </Link>
        </li>
      )
    }
  }

  // currently checks if the user has an avatar image
  function renderAvatarStep(item, key) {
    const hasProfilePicture = [me]
      .concat(item.checkProperty.split('.'))
      .reduce((a, b) => (a[b] ? a[b] : {}))

    if (Object.keys(hasProfilePicture).length === 0) {
      return (
        <li
          className="my-4"
          key={key}
          onClick={() => {
            item.handler.fn()
          }}
        >
          <span className="text-link">{item.title}</span>
          <i className="fal fa-times-circle text-danger ml-2"></i>
          <hr className="mt-4" />
        </li>
      )
    } else {
      return (
        <li className="my-4" key={key}>
          <span className="text-muted">{item.title}</span>
          <i className="fal fa-check-circle text-success ml-2"></i>

          <hr className="mt-4" />
        </li>
      )
    }
  }

  // Renders the complete profile section
  function renderProfileActivationStep() {
    const stepsRequired = [
      {
        title: 'Activeer persoonlijke interesses',
        handler: {
          type: 'link',
          url: '/kies-je-branche',
        },
        checkProperty: 'subscribedTopics',
        validator: 'one-of-array-in-object-is-empty',
        stepReward: 50,
      },
      {
        title: 'Voeg een profiel foto toe',
        handler: {
          type: 'onclick',
          url: '/kies-je-branche',
        },
        checkProperty: 'avatar.url',
        validator: 'is-not-null',
        stepReward: 50,
      },
    ]

    return (
      <ul className="list-unstyled">
        {stepsRequired.map((item, key) => {
          if (
            item.handler.type === 'link' &&
            item.validator === 'one-of-array-in-object-is-empty'
          ) {
            return renderTopicStep(
              me.subscribedTopics,
              {
                ...item,
              },
              key
            )
          } else if (
            item.handler.type === 'onclick' &&
            item.validator === 'is-not-null'
          ) {
            return renderAvatarStep(item, key)
          } else {
            return item
          }
        })}
      </ul>
    )
  }

  // TODO: END REFACTORING

  return (
    <div className={`page me`}>
      <Head pageTitle="Mijn profiel" />
      <ScrollRestoration />
      {me ? (
        <section className="py-5">
          <div className="container">
            <Breadcrumb
              pages={[
                {
                  slug: '/profiel',
                  title: 'Mijn profiel',
                },
              ]}
            />
            <h1 className="hero-heading mb-0">Mijn profiel</h1>
            <p className="text-muted mb-5">
              Beheer je persoonlijke instellingen.
            </p>

            <div className="row">
              <div className="col-lg-7">
                <div className="text-block">
                  <div className="row mb-3">
                    <div className="col-sm-9">
                      <h5>Persoonlijke gegevens</h5>
                    </div>
                    <div className="mx-3" id="personalDetails">
                      <ProfileForm />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <h5>
                  {progress === 100
                    ? 'Je profiel is compleet  🎉'
                    : 'Maak je profiel compleet'}
                </h5>
                <div className="progress">
                  <div
                    className="progress-bar bg-theme-primary"
                    role="progressbar"
                    style={{ width: `${progress.toString()}%` }}
                    aria-valuenow={progress.toString() + '%'}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                {renderProfileActivationStep()}

                {progress < 100 && (
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary mt-2">
                      Voltooi later
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 ml-lg-auto"></div>
            </div>
          </div>
        </section>
      ) : (
        <div />
      )}
    </div>
  )
}
