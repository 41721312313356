import React from 'react'

const ScrollControl = () => {
  const handleClick = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }
  return (
    <div
      onClick={handleClick}
      className="scrollControl--container p-2 text-center"
    >
      <i className="fas fa-arrow-up mt-1"></i>
    </div>
  )
}

export default ScrollControl
