/** @flow */

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import AsyncSelect from 'react-select/async';
import useAxios from 'axios-hooks'
// import { DebounceInput } from 'react-debounce-input';
import { Form, InputGroup } from 'react-bootstrap'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const defaultProps = {
  className: '',
  path: '/collections/detail',
}

/**
 * Render the image component connected to a Strapi image object.
 * @constructor
 * @param {object} props                    - The properties object.
 * @param {(string)} [props.className]      - Optional className.
 * @param {(string)} [props.endpoint]       - The endpoint to search from
 * @return {html}                           - The html element.
 */
export default (instanceProps) => {
  const props = { ...defaultProps, ...instanceProps }
  const [keyword] = useState('')
  const [, setOptions] = useState([])
  const [{ data, loading }, performSearch] = useAxios(
    { url: '/projects' },
    { manual: true }
  )

  const onSearch = (query) => {
    performSearch({
      params: {
        _q: query,
        _limit: 5,
      },
    }).then((res) => {
      setOptions(res.data)
    })
  }

  return (
    <AsyncTypeahead
      options={data}
      isLoading={loading}
      query={keyword}
      className="form-control form-control-sm border-0 border-left-0 shadow-0 m-0 p-0 w-100"
      id="_q"
      labelKey="title"
      filterBy={() => true}
      maxResults={10}
      minLength={3}
      onSearch={onSearch}
      placeholder="Waar ben je naar op zoek?"
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <InputGroup>
          <InputGroup.Prepend className="border-1 border-right-0">
            <InputGroup.Text className="border-1 border-right-0 shadow-0 bg-transparent">
              <i className="fa fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="email"
            className="form-control form-control-sm border-0 shadow-0 m-0 p-0 w-100"
            style={{ borderLeft: 0 }}
            {...inputProps}
            ref={(input) => {
              // Be sure to correctly handle these refs. In many cases, both can simply receive
              // the underlying input node, but `referenceElementRef can receive a wrapper node if
              // your custom input is more complex (See TypeaheadInputMulti for an example).
              inputRef(input)
              referenceElementRef(input)
            }}
          />
        </InputGroup>
      )}
      renderMenuItemChildren={(option, itemProps, index) => (
        <div className="card border-0 m-0" key={index}>
          <Link
            to={`${props.path}/${option.slug}`}
            className="tile-link"
          ></Link>
          {option.images.length ? (
            <img
              alt="search-thumbnail"
              src={option.images[0].formats.thumbnail.url}
              className="bg-image w-25 mr-2"
            />
          ) : null}
          <div
            className="card-body text-truncate p-2"
            style={{ marginLeft: '25%' }}
          >
            <h6 className="text-truncate mb-0">
              {option.professionals[0].name}
            </h6>
            <p className="text-truncate m-0">{option.title}</p>
            {option.categories.length ? (
              <small className="text-truncate m-0 text-muted">
                {option.categories[0].title}
              </small>
            ) : null}
          </div>
        </div>
      )}
      useCache={true}
    />
  )
}
