import React from 'react'
import Head from '../../core/Head'
import ScrollRestoration from '../../core/ScrollRestoration'
import BlockHero from '../../blocks/hero/View'

export default (props) => {
  return (
    <div className={`page`}>
      <Head
        pageTitle="404 - Pagina niet gevonden"
        seo={[
          { meta_description: '', meta_title: '', __component: 'seo.meta' },
          { og_image: '', og_title: '', __component: 'seo.open-graph' },
        ]}
        {...props}
      />
      <ScrollRestoration />

      <BlockHero
        error = {true}
        title="404"
        content={<span>Ooow, voor deze ruimte missen we nog wat inspiratie… <br/> Zorg dat jouw werkruimte niet zo saai en leeg blijft!</span>}
        image={{
          url: 'NotFound_XLArJmlHQ.png',
        }}
        key="error-hero-404"
        slug="404"
        link={{
          url: '/collections',
          title: 'Get inspired!',
          class: 'btn btn-light text-gold '
        }}
      />
    </div>
  )
}
