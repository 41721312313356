// Naam, Telefoonnummer, Emailadres, Adres, Postcode_Plaats

const columnOne = [
  {
    label: "Bedrijfsnaam",
    name: "bedrijfsnaam",
    type: "text",
    required: true,
    validationType: "string",
    validation: [
      {
        type: "required",
        params: ["Verplicht"],
      },
    ],
  },
  {
    label: "Inspiratie pakket",
    name: "inspiratie",
    type: "select",
    select: {
      values: ["Inspiratiepakket"],
    },
  },
  {
    labelprefix: "Ik ga akkoord met de ",
    link: {
      text: "algemene voorwaarden",
      url: "/algemene-voorwaarden"
    },
    labelsuffix: "",
    name: "algemene_voorwaarden",
    type: "checkbox",
    checkbox: {
      values: ["Ik ga akkoord met de algemene voorwaarden"],
    },
    required: true,
    validationType: "string",
    validation: [
      {
        type: "required",
        params: [ "Verplicht"],
      },
    ],
  },
  {
    name: "nieuwsbrief",
    type: "checkbox",
    checkbox: {
      values: ["Ik wil graag de nieuwsbrief ontvangen"],
    },
  },
];

const columnTwo = [
  {
    label: "E-mailadres:",
    name: "email",
    type: "email",
    required: true,
    validationType: "string",
    validation: [
      {
        type: "required",
        params: ["Verplicht"],
      },
      {
        type: "email",
        params: ["Ongeldig email adres"],
      },
    ],
  },
];

export const inspirationDataFields = [
  {
    fields: { columnOne, columnTwo },
  },
];
