import React from 'react';
import { PusherProvider} from "@harelpls/use-pusher";
import { useAuth } from '../context/auth';


export default (props) => {
  const {me: user} = useAuth();


  const config = {
    clientKey: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authEndpoint: process.env.REACT_APP_API_URL + "/pusher/auth",
    auth: {
      headers: { Authorization: `Bearer ${user?.jwt}` },
    }
  }
  
 return  <PusherProvider {...config}>
    { props.children }
  </PusherProvider> 
}
