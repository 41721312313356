import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'
import * as auth from '../auth-provider'
import { Button, Navbar } from 'react-bootstrap'

import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Breadcrumb from '../elements/Breadcrumb'

import Directory, { Card, Keyword } from '../components/directory'
import ScrollAnimation from 'react-animate-on-scroll'

export default (props) => {
  const { data, isLoading, isError, error } = useQuery('me', async () =>
    client('users/me', { token: await auth.getToken() })
  )

  if (isLoading) {
    return <Loading {...props} />
  }

  if (isError) {
    console.log('FAVORITES ERROR', error)
    return <ErrorPage {...props} />
  }

  const renderWishlist = (wishlist) => {
    if (!wishlist || !wishlist.length) {
      return (
        <div className="pt-3 pb-3 mx-3 mx-lg-5">
          <div className="container-fluid">
            <div className="row mb-4">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                offset={0}
                animateOnce={true}
              >
                <h3>Nog geen favorieten</h3>
                <Link to="/collections" className="btn btn-primary">
                  Start je zoektocht
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      )
    }

    return (
      <Directory
        endpoint="projects"
        displayPagination={true}
        query={{
          id_in: wishlist,
        }}
        filters={[
          <Keyword
            name="_q"
            placeholder="Zoek op trefwoord"
            className="col-4"
          />,
        ]}
        renderWrapper={({
          results,
          renderLoading,
          renderPagination,
          renderFilters,
        }) => (
          <div className="pt-3 pb-3 mx-3 mx-lg-5">
            <div className="container-fluid">
              <div className="row mb-4" style={{ margin: '0 -30px' }}>
                <Navbar collapseOnSelect expand="lg" className="w-100 px-lg-0">
                  <Navbar.Toggle
                    aria-controls="responsive-filters"
                    className="border-0 m-0 p-0 w-100 mb-3"
                  >
                    <Button
                      variant="outline-primary"
                      block
                      className="btn-wide d-block"
                    >
                      Filteren
                    </Button>
                  </Navbar.Toggle>

                  <Navbar.Collapse
                    id="responsive-filters"
                    className="collapse navbar-collapse"
                  >
                    {renderFilters()}
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <div className="row my-2" style={{ margin: '0 -30px' }}>
                {renderLoading()}
                {results()}
              </div>
              <div className="row" style={{ margin: '0 -30px' }}>
                {renderPagination()}
              </div>
            </div>
          </div>
        )}
        renderItem={(item, index) => (
          <div
            className="col-md-3 col-sm-12 mb-4 mt-1"
            key={`${index}-${item.id}`}
          >
            <ScrollAnimation
              animateIn="animate__animated animate__fadeIn"
              offset={0}
              animateOnce={true}
              delay={(index % 4) * 50}
            >
              <Card {...item} />
            </ScrollAnimation>
          </div>
        )}
      />
    )
  }

  return (
    <div className={`page favorites`}>
      <Head
        pageTitle="Mijn favorieten"
        seo={[
          { meta_description: '', meta_title: '', __component: 'seo.meta' },
          {
            og_image: '',
            og_title: '',
            og_description: '',
            __component: 'seo.open-graph',
          },
        ]}
      />
      <ScrollRestoration />

      <div className="container-fluid pt-4 pb-3 px-lg-5">
        <div className="row">
          <div className="col-xl-8">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={1 * 50}
              offset={-50}
            >
              <Breadcrumb
                pages={[
                  {
                    title: 'Mijn favorieten',
                    slug: '/favorieten',
                  },
                ]}
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUpSmall"
              animateOnce={true}
              delay={2 * 50}
            >
              <h1>Mijn favorieten</h1>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      {renderWishlist(data.wishlist)}
    </div>
  )
}
