// Dependencies
import React from "react";
import { Modal } from "react-bootstrap";

const ModalWrapper = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      aria-labelledby="modal-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
