import React from 'react'
import ModalWrapper from './ModalWrapper'
import {Modal, Dropdown, Image} from 'react-bootstrap'; 
import useAxios from 'axios-hooks'
import Loading from '../Loading'
import Placeholder from '../../seed/icons/placeholder-icon.svg'


const MyProfessionals = ()=>{
const [profesisonalsModalShow, setprofesisonalsModalShow] = React.useState(false)
const [{ data, loading }] = useAxios(`users/me/professionals`)

const handleClick = (e, redirectURL)=>{
    e.preventDefault(); 
    setprofesisonalsModalShow(false); 
    window.open(process.env.REACT_APP_URL+ redirectURL, "_self")
}

// CHAT TODO 
    return <>
    {/* <Dropdown.Item>
    <div 
    onClick={() => setprofesisonalsModalShow(true)}
    >
      <i className="fa fa-address-card mr-2 text-muted"></i>
      Mijn professionals
    </div>
  </Dropdown.Item> */}
    
    <ModalWrapper
show={profesisonalsModalShow}
onHide={() => setprofesisonalsModalShow(false)}
size="lg"
dialogClassName="overflow-hidden"
className="quotation--modal"
backdrop= {false}
>
<Modal.Header closeButton className="px-5 py-4">
  <h4>Kies een professional...</h4>
</Modal.Header>
<Modal.Body className="p-0 m-0 container overflow-auto">
  <div className="row m-0 p-0">
    {loading ? <Loading />: (data && data !== null) &&
    data.map(prof=>{
       return <div key = {prof.id} className = "myprofessional-row w-100 " 
       onClick = {(e)=>handleClick(e, `/members/profile/${prof.slug}?userMode=true`)}
       >
           <div className= "media w-100 d-flex align-items-center justify-content-start pl-4">
         <Image src = {prof?.avatar?.formats?.thumbnail?.url ?? Placeholder} className = "avatar avatar-sm mr-3"/>
           <h4 className = "media-body text-truncate p-3">{prof.name}</h4> 
           </div>
        </div>
    })
    }
  </div>
</Modal.Body>
</ModalWrapper>
</>
}

export default MyProfessionals; 