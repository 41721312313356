import React from 'react'
import { useQuery } from 'react-query'
import Head from '../core/Head'
import { client } from '../utils/api-client'
import { Redirect } from 'react-router-dom'
import Loading from '../elements/Loading'

import BlockHero from '../blocks/hero/View'
// import ScrollAnimation from 'react-animate-on-scroll'
import { getImageURL } from '../core/helpers/Image'
import EventCard from '../components/events/EventCard'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import EventsRecapCard from '../components/events/EventsRecapCard'
import { useAuth } from '../context/auth'
import ScrollControl from '../elements/ScrollControl'
import moment from 'moment'
import ScrollRestoration from '../core/ScrollRestoration'

export default (props) => {
  let swiper
  const { me } = useAuth()
  React.useEffect(() => {
    new Swiper(swiper, {
      slidesPerView: 'auto',
      speed: 1500,
      loop: false,
      autoHeight: false,
      effect: 'swipe',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      breakpoints: {
        576: {
          slidesPerView: 'auto',
          centeredSlides: false,
          loop: false,
        },
        768: {
          slidesPerView: 'auto',
          centeredSlides: false,
          loop: false,
        },
        1430: {
          slidesPerView: 'auto',
          centeredSlides: false,
          loop: false,
        },
      },
    })
  })
  Swiper.use([Navigation, Pagination, Autoplay])
  // Get the page data
  const { data, isLoading } = useQuery(['eventsPage'], () =>
    client(`pages?slug=evenementen`)
  )

  // Get the events data
  const { data: events, isLoading: isLoadingEvents } = useQuery(
    ['events'],
    () =>
      client(
        `events?date_gte=${moment(new Date()).format(
          'YYYY-MM-DD'
        )}&_sort=date:asc,start_time:asc&_limit=-1`
      )
  )

  // Get the events data
  const { data: expiredEvents, isLoading: isLoadingPastEvents } = useQuery(
    ['expiredEvents'],
    () =>
      client(
        `events?date_lte=${moment(new Date()).format(
          'YYYY-MM-DD'
        )}&_sort=date:desc,start_time:desc&_limit=-1`
      )
  )

  const renderCards = (expiredEvents) =>
    expiredEvents.map((event, index) => {
      return (
        <div
          className="recap--card col-xl-4 col-md-6 col-10 mb-5 swiper-slide"
          key={`projec§t-related-items-card-${event._id || event.id}-${index}`}
        >
          <EventsRecapCard {...event} />
        </div>
      )
    })

  const page = data && data[0]
  const seo = page && page['SEO']

  return isLoadingEvents || isLoading || isLoadingPastEvents ? (
    <Loading {...props} />
  ) : page ? (
    <div className={`page default navbar-transparant `}>
      <ScrollRestoration />
      <Head seo={seo} pageTitle={page.title || 'Default'} />

      {/* Hero */}
      <BlockHero
        noOverlay={true}
        title=""
        image={{
          url: getImageURL(page.image),
        }}
        key="event-hero"
        slug="event"
      />
      <div className="events-overview--container container px-5 px-md-2 px-lg-6 pb-2">
        {/* Floating Card */}
        <div className="row mb-5">
          <div className="  card  col-lg-6 col-md-12 col-12 shadow p-3 mb-1 bg-white rounded ">
            <div className="card-body">
              <h2 className="card-title">Ontmoet en raak geïnspireerd!</h2>
              <p className="card-text text-muted">
                Hque events zijn dé gelegenheid om op in een ongedwongen sfeer
                te netwerken onder het genot van een hapje en een drankje. Hier
                komen architecten en makers samen op unieke en inspirerende
                locaties. Geen traditionele netwerkclub of massaal evenement,
                maar een platform waarbij we elkaar op informele en persoonlijke
                wijze ontmoeten en beter leren kennen. Dat betekent voor jou
                meer relaties, meer kennis en nieuwe ideeën!
              </p>
            </div>
          </div>
        </div>
        {/* Upcoming events */}
        <div className="row mb-5">
          <h2 className="mb-4 col-12 px-0">Aankomende events</h2>
          {events && events.length > 0 ? (
            events.map((entry) => {
              let modifiedEntry = Object.assign({}, entry)
              modifiedEntry.attendants = modifiedEntry.attendants.filter(
                (e) => {
                  return e.approved
                }
              )
              return <EventCard {...modifiedEntry} key={modifiedEntry.id} />
            })
          ) : (
            // No events found
            <div className="card row shadow p-3 mb-5 bg-white rounded  w-100">
              <div className="card-body text-center">
                <h3 className="card-title">Geen aankomende evenementen</h3>
                <p className="card-text text-muted">
                  Nieuwe evenementen zullen in de toekomst worden toegevoegd,
                  probeer het over een tijdje opnieuw.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Expired events */}
        <div>
          <h2 className="mb-4">Afgelopen evenementen</h2>
          {expiredEvents && expiredEvents.length > 0 ? (
            <div className="row justify-content-center">
              <div
                className="swiper-container h-100 blog__related-swiper"
                ref={(c) => (swiper = c)}
              >
                <div className="swiper-wrapper">
                  {renderCards([...expiredEvents])}
                </div>
                <div className="events__body-swiper-navigation--container">
                  <div className=" events__body-swiper-navigation d-flex">
                    <div className="swiper-button-prev p-3 ml-3 mr-2 h5 rounded"></div>
                    <div className="swiper-button-next p-3 mx-2 h5 rounded"></div>
                  </div>
                </div>

                <div className="swiper-pagination events__body-swiper-pagination--container "></div>
              </div>
            </div>
          ) : (
            <div className="card row shadow p-3 mb-5 bg-white rounded  w-100">
              <div className="card-body text-center">
                <h3 className="card-title">Geen afgelopen evenementen</h3>
                <p className="card-text text-muted">
                  Geen afgelopen evenementen gevonden.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="blog__cta">
        <div className="container">
          <div className="row text-center align-items-xl-center justify-content-center">
            <div className="col-md-8">
              <div className="d-flex flex-column">
                <h2>Wil jij naar onze events?</h2>
                <p>
                  Ontmoeten, kennis delen, ideeën opdoen en inspireren. Word lid
                  van hque!
                </p>
              </div>
              <a href="/lid-worden" className="btn btn-primary rounded">
                Word lid van HQUE
              </a>
            </div>
          </div>
        </div>
        <ScrollControl />
      </div>
    </div>
  ) : (
    <Redirect to="/pagina-niet-gevonden" />
  )
}
