import Axios from 'axios'
import { configure } from 'axios-hooks'
const REACT_APP_API_URL = 'http://localhost:1337'
import { canUseDOM } from '../utils'

export const getAuthToken = () => {
  return canUseDOM ? localStorage.getItem('__auth_provider_token__') : null
}

export const isAuthenticated = () => {
  return getAuthToken() !== null
}

export const baseURL = () => {
  return process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : REACT_APP_API_URL
}

export const setupClient = () => {
  const axios = Axios.create({
    baseURL: baseURL(),
  })

  axios.interceptors.request.use((config) => {
    if (!isAuthenticated()) {
      config.headers.common.Authorization = ''
    } else {
      config.headers.common.Authorization = `Bearer ${getAuthToken()}`
    }
    return config
  })

  configure({ axios })
}
