const columnOne = [
  {
    label: "Vertel ons over uw workspace",
    name: "workspace",
    type: "textarea",
    required: true,
    validationType: "string",
    validation: [
      {
        type: "required",
        params: ["Verplicht"],
      },
    ],
  },
];

const stepTwo = {
  fields: { columnOne },
};

export default stepTwo;
